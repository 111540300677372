.text-image-answers__block{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #F9F7F4;
    height: 100%;
    min-height: 100vh;
    .block__container{
        width: 85%;
        margin-top: 25px;
        margin-bottom: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .container__item{
            padding: 8px;
            width: 100%;
            height: 74px;
            background: white;
            border-radius: 15px;
            display: flex;
            padding: 5px;
            flex-direction: row;
            position: relative;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 12px;
            .container-item__text{
                font-family: Poppins;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 120%;
                margin: 0;
                margin-left: 16px;
                width: 30%;
            }
            .container-item__img{
                height: 100%;
            }
            .container-item__text--active{
                color: #222427;
            }
            .container-item__text--unactive{
                color: #222427;
            }
        }
        .container__item--active{
            border: 1px solid #4380FF;
            background: #F1F6FF;
        }
        .container__item--unactive{
            border: 1px solid #EEEEEE;
        }
    }
}
.text-image-answers__block--fadeout{
    animation-name: fadeout;
    animation-duration: .5s;
}
.text-image-answers__block--fadein{
    animation-name: fadein;
    animation-duration: .5s;
}