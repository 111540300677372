.header {
  width: 90%;

  position: absolute;
  margin-top: 55px;
  max-width: 430px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 1;
  .header__block {
    width: 100%;
    display: flex;
    margin-top: 18px;
    flex-direction: row;
    justify-content: space-between;
    .header__text1 {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: rgba(46, 50, 63, 0.7);
    }
    .header__text2 {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: #70748e;
    }
  }
}
.header2 {
  width: 90%;
  height: 48px;
  position: fixed;
  margin-top: 20px;
  max-width: 430px;
  display: block;
  z-index: 1;
  .header2__icon {
    height: 48px;
    width: 48px;
    background: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    cursor: pointer;
  }
}
.menu {
  width: 100%;
  max-width: 500px;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  height: 100vh;
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  .menu__container {
    padding-top: 94px;
    max-width: 340px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    height: 100%;
    background: white;
    .container__icon {
      position: absolute;
      top: 20px;
      width: 48px;
      height: 48px;
      border-radius: 24px;
      right: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ffffff;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      .icon__img {
        height: 24px;
        width: 24px;
      }
    }
    .container__row {
      padding-bottom: 16px;
      padding-top: 16px;
      border-bottom: 1px solid #b8b9c7;
      display: flex;
      margin-bottom: 16px;
      width: 80%;
      .row__img {
        width: 24px;
        height: 24px;
        margin-right: 16px;
      }
      .row__text {
        font-family: Poppins;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 120%;
        text-align: center;
        margin: 0;
        color: #191919;
      }
    }
    .container__email {
      font-family: Poppins;
      width: 80%;
      text-align: left;
      padding-left: 10%;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 108%;
      letter-spacing: 0.05em;
      color: #7e808e;
    }
  }
}
.progress {
  height: 6px;
  position: absolute;
  margin-top: 0px;
  border-radius: 3px 3px 3px 3px;
  box-shadow: inset 0px 2px 4px rgba(23, 197, 99, 0.2) !important;
  background: white;
  .progress-bar {
    border-radius: 3px 3px 3px 3px;
    background: #15c963 !important;
  }
}
