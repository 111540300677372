.button {
  width: 100%;

  font-weight: 600;
  font-size: 16px;
  line-height: 108.1%;

  text-align: center;
  letter-spacing: 0.05em;
  text-transform: capitalize;

  color: #fff;

  background: #4380ff;
  box-shadow: 0px 4px 10px rgba(109, 98, 229, 0.21);
  border-radius: 38px;
  border: none;

  transition: background 0.35s;

  display: flex;
  align-items: center;
  justify-content: center;

  &__icon {
    margin-right: 16.5px;
  }

  &--disabled {
    background: rgba(67, 128, 255, 0.3);
  }

  &--fixed {
    position: fixed;
  }

  @media screen and (max-width: 331px) {
    max-width: 250px;
  }

  @media screen and (min-width: 1024px) {
    cursor: pointer;

    &--disabled {
      cursor: not-allowed;
    }
  }
}
