.authorize-form {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

	&__description {
		margin: 15px 0 0;

		text-align: center;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;

		opacity: 0.9;
	}

	&__link {
		margin-top: 30px;

		font-weight: normal;
		font-size: 14px;
		line-height: 15px;
		letter-spacing: 0.05em;
		text-transform: capitalize;

		color: var(--color--white);

		opacity: 0.8;

		text-decoration: none;

		&:active {
			color: var(--color--pink);
		}
	}
}
