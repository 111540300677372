.personality {
  .user__card {
    height: 164px;
    width: 85%;
    background: white;
    box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 30px 24px 29px;
    margin-bottom: 16px;
    .card__header {
      font-family: Poppins;
      font-style: normal;
      font-weight: bold;
      margin-top: 0px;
      font-size: 14px;
      line-height: 130%;
      color: #2e323f;
      margin-bottom: 12px;
    }
    .card__body {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 130%;
      color: #71758f;
      margin-top: 16px;
      margin-bottom: 0px;
    }
  }
  .life__cards {
    border: 1px solid rgba(231, 233, 237, 0.25);
    box-shadow: 0px 4px 16px rgba(12, 31, 61, 0.04);
    border-radius: 14px;
    width: 85%;
    background: white;
    .life__card {
      padding: 25px;
      border-bottom: 1px dashed silver;
      .card__header {
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.248359px;
        opacity: 0.87;
      }
      .text1 {
        color: #9041c7;
      }
      .text2 {
        color: #0f712b;
      }
      .text3 {
        color: #ff820f;
      }
      .text4 {
        color: #006de7;
      }
      .body1 {
        color: #512570;
      }
      .body2 {
        color: #005f1b;
      }
      .body3 {
        color: #6b5335;
      }
      .body4 {
        color: #004899;
      }
      .card__body {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.25px;
        opacity: 0.8;
      }
    }
  }
}
.personality__text3 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.273195px;
  color: #222427;
  opacity: 0.8;
  width: 75%;
  margin-top: 10px;
  margin-bottom: 20px;
}
