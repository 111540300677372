.email__block {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 57px;
  align-items: center;
  background: #f9f7f4;
  .email__header {
    margin-top: 10px;
    width: 82%;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 120%;
    text-align: center;
    color: #222427;
  }
  .email__text {
    width: 75%;
    margin-top: 5px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    color: #222427;
    opacity: 0.9;
  }
  .email__image {
    width: 70%;
    max-width: 220px;
    margin-left: -30px;
    margin-top: -40px;
    margin-bottom: -20px;
  }
  .email__input {
    width: 90%;
    margin-bottom: 15px;
    border: none;
    outline: none;
    border: 1px solid rgba(109, 98, 229, 0.5);
    box-sizing: border-box;
    border-radius: 20px;
    background: white;
    height: 50px;
    padding-left: 20px;
  }
  .email__input--active {
    background: #f1f6ff;
    border: 1.5px solid #4380ff;
    color: #4380ff;
    box-sizing: border-box;
    box-shadow: 0px 0px 8px rgba(116, 89, 172, 0.08);
    border-radius: 13px;
  }

  .email__input--unactive {
    background: #ffffff;
    border: 1px solid rgba(27, 24, 48, 0.13);
    box-sizing: border-box;
    border-radius: 13px;
    color: #222427;
  }
  .email__input::placeholder {
    opacity: 0.6;
  }
}
.email__text2 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  margin-top: 10px;
  font-size: 10px;
  line-height: 130%;
  text-align: center;
  letter-spacing: 0.25px;
  color: rgba(133, 143, 158, 0.8);
  width: 90%;
  // margin-bottom: 80px;
  span {
    color: #4380ff;
    opacity: 0.8;
  }
}

.email-screen {
  &-privacies {
    margin: 20px 25px 10px;
    font-family: "Poppins", sans-serif;

    display: grid;

    grid-auto-rows: 39px;
    gap: 17px;

    .privacy {
      display: grid;
      grid-template-columns: 15px 1fr;
      gap: 10px;

      &-checkbox {
        width: 15px;
        height: 15px;

        display: flex;
        justify-content: center;
        align-items: center;

        border: 1px solid #4380ff;
        border-radius: 3px;
        background: transparent;

        &__image {
          opacity: 0;
          height: 4.5px;
          width: 7px;
          transition: opacity 0.35s;
        }

        &--checked {
          .privacy-checkbox__image {
            opacity: 1;
          }
        }
      }

      &-text {
        font-size: 10px;
        line-height: 130%;

        letter-spacing: 0.25px;

        color: #808897;

        &__link {
          color: #4380ff;
          text-decoration: none;
        }
      }
    }
  }

  &__undertext {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 500;
    margin-top: 10px;
    font-size: 10px;
    line-height: 130%;
    text-align: center;
    letter-spacing: 0.25px;
    color: rgba(133, 143, 158, 0.8);
    width: 90%;

    .undertext-privacy__link {
      color: #4380ff;
      text-decoration: none;
    }
  }
  &--active {
    opacity: 1;
  }
}
