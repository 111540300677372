.questionbox{
    min-height: 150px;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .questionbox__header{
        width: 90%;
        text-align: left;
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 120%;
        color: #2E323F;
        margin-top: 70px;
        margin-bottom: 0px;

    }
    .questionbox__text{
        width: 90%;
        text-align: left;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 21px;
        color: #70748E;
        margin-top: 16px;
        margin-bottom: 0px;
        
    }
}
.questionbox2{
    min-height: 150px;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .questionbox2__header{
        width: 90%;
        text-align: left;
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 120%;
        color: #2E323F;
        margin-top: 70px;
        margin-bottom: 0px;

    }
    .questionbox2__text{
        width: 90%;
        background: #FBEBB3;
        text-align: left;
        color:black;
        opacity:0.9;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 21px;
        border-radius: 14px;
        margin-top: 16px;
        margin-bottom: 0px;
        padding:18px;        
    }
}