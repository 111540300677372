.header-top {
  padding: 10px 20px 10px;
  height: 50px;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: space-between;

  &-container {
    z-index: 3;

    width: 100%;
    position: fixed;
    top: 0;
    left: 0;

    background: #fef7ed;

    transition: background 0.5s;

    &--opened-burger {
      background: #fff !important;
    }
  }

  .back-button {
    width: 47.77px;
    height: 49px;
    display: flex;
    align-items: center;

    user-select: none;
    transition: opacity 0.35s ease-in-out;
    opacity: 1;

    &__text {
      font-size: 14px;
      line-height: 21px;
    }

    @media screen and (min-width: 1024px) {
      cursor: pointer;
    }
  }

  .header-top-logo {
    span {
      font-family: Poppins;
      font-style: normal;
      font-weight: bold;
      font-size: 14.2767px;
      line-height: 21px;

      color: #222427;
    }
    img {
      margin-right: 5px;
      width: 30px;
      height: 30px;
    }
  }

  .burger-icon {
    margin-left: 28px;
    padding: 6px 0;
    object-fit: contain;

    display: grid;
    gap: 5px;
    grid-template-rows: auto auto;

    user-select: none;

    @media screen and (min-width: 1024px) {
      cursor: pointer;
    }

    &__line {
      width: 22px;
      height: 2px;

      transition: transform 0.35s;
    }

    &--open {
      .burger-icon__line--bottom {
        transform: translate(0, -7px) rotate(45deg);
      }

      .burger-icon__line--top {
        transform: rotate(-45deg);
      }
    }
  }
}

.back-button--hide {
  cursor: auto !important;
  opacity: 0 !important;
}
