.upsale__screen1 {
  padding-top: 50px;
  width: 100%;
  background: #fef7ed;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  .screen1__text1 {
    padding: 20px 19px 15px 27px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    background: none;
    text-align: left;
    font-size: 25px;
    line-height: 126%;
    color: #222427;
  }
  .screen1__lineblock {
    display: flex;
    width: 100%;
    padding: 0px 44px 0px 27px;
    box-sizing: border-box;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 10px;
    .lineblock__check {
      width: 37px;
      height: 37px;
      padding: 0px 11px 0px 0px;
      box-sizing: border-box;
      align-self: flex-start;
      .check__img {
        width: 15px;
        height: 15px;
      }
    }
    .lineblock__text {
      width: auto;
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      margin-top: 2px;
      line-height: 22px;
      margin-bottom: 0px;
      color: #222427;
    }
  }
  .upsale__img {
    width: 100%;
  }
  .upsale__whiteblock {
    width: 100%;
    padding: 10px 27px 22px 27px;
    box-sizing: border-box;
    display: flex;
    height: auto;
    flex-direction: column;
    align-items: center;
    background: white;
    .whiteblock__text1 {
      text-align: center;
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.273195px;
      color: #222427;
    }
    .whiteblock__text4 {
      font-family: Poppins;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.302918px;
      text-transform: uppercase;
      color: black;
    }
    .whiteblock__button {
      background: #4380ff;
      box-shadow: 0px 4px 10px rgba(109, 98, 229, 0.21);
      border-radius: 38px;
      width: 90%;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 108.1%;
      display: flex;
      align-items: center;
      border: none;
      outline: none;
      text-align: center;
      letter-spacing: 0.05em;
      text-transform: capitalize;
      color: #ffffff;
    }
    .whiteblock__undertext {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 27px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.248359px;
      color: #222427;
      margin-top: 10px;
      opacity: 0.4;
      border: none;
      background: none;
    }
  }
  .upsale__header {
    width: 100%;
    height: 200px;
    background: #4380ff;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    .header__text {
      font-family: Poppins;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 30px;
      width: 75%;

      letter-spacing: 0.248359px;
      color: #fffcf8;
    }
  }
  .upsale__main {
    width: 90%;
    margin-top: -50px;
    .main__block {
      display: flex;
      cursor: pointer;
      flex-direction: row;
      align-items: center;
      padding: 12px 15px 17px 15px;
      border: 2px solid #4380ff;
      position: relative;
      box-sizing: border-box;
      box-shadow: 0px 4px 14px rgba(34, 36, 39, 0.12);
      border-radius: 16px;
      background: white;
      margin-bottom: 16px;
      height: 85px;
      .block__check {
        position: absolute;
        height: 19px;
        width: 19px;
        top: 20px;
        right: 15px;
      }
      .block1 {
        height: 40px;
        padding: 9px;
        width: 40px;
        background: #f5faf7;
        border-radius: 20px;
        margin-right: 14px;
      }
      .block2 {
        position: relative;
        .block2__header {
          font-family: Poppins;
          font-style: normal;
          font-weight: 600;
          margin-bottom: 5px;
          font-size: 13px;
          line-height: 17px;
          letter-spacing: 0.273195px;
          color: #222427;
          width: 95%;
        }
        .block2__text1 {
          font-family: Poppins;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 100%;
          text-align: center;
          margin-right: 10px;
          color: #fd5e2c;
        }
        .block2__text2 {
          font-family: Poppins;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 100%;
          text-align: center;
          color: #222427;
          margin-right: 10px;
        }
        .block2__text3 {
          font-family: Poppins;
          font-style: normal;
          font-weight: bold;
          font-size: 13px;
          line-height: 100%;
          text-align: center;
          color: #ffffff;
          padding: 3px 8px 3px 8px;
          background: #fd5e2c;
          border-radius: 5px;
        }
      }
    }
    .main__block--unactive {
      border: 2px solid #e7e9ed;
    }
  }
  .upsale3__button {
    background: #4380ff;
    box-shadow: 0px 4px 10px rgba(109, 98, 229, 0.21);
    border-radius: 38px;
    width: 90%;
    position: fixed;
    bottom: 50px;
    border: none;
    outline: none;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    height: 48px;
    line-height: 108.1%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 425px;
    letter-spacing: 0.05em;
    text-transform: capitalize;
    color: #ffffff;
  }
  .undertext {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    position: fixed;
    bottom: 10px;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.248359px;
    color: #222427;
    opacity: 0.4;
  }
}
.cancel__anytime {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.273195px;
  color: rgba(34, 36, 39, 0.6);
  opacity: 0.3;
}
