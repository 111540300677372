.billy__block{
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    .block__img{
        width: 60%;
        margin-top: 85px;
    }
    .block__text1{
        margin-top: 50px;
        width: 76%;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 140%;       
        text-align: center;  
        color: #2869F1;
    }
    .block__text2{
        width: 70%;
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 22px;
        text-align: center;
        color: #222427;
        opacity: 0.9;
    }
}