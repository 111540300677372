.fading-slider{
    width: 90%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.fading-slides{
    width:100%;
    height:206px;
    display: flex;
    justify-content: center;
    position: relative;
}
.fading-slider__container{
    width: 100%;
    height: 206px;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    
}
.fading-slider__img{
    width: 304px;
    height: 200px;
}
.fading-slider__container-active{
    width:100%;
    height:200px;
    position: absolute;
    opacity: 1;
    animation-name: fadein;
    animation-duration: .5s;

}


.fading-slider__container-unactive{
    width:100%;
    height:200px;
    position: absolute;
    opacity: 0;
    animation-name: fadeout;
    animation-duration: .5s;
}
@keyframes fadein{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
@keyframes fadeout{
    0%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}
.fading-slider-control{
    display: flex;
    margin-top:20px;
    justify-content: space-between;
    align-items: center;
    width:50%;

}
.fading-slider-control p{
    margin: 0px;
}
.slider__text__new{
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #0C1F3D;
}
.arrow-button{
    background: white;
    padding: 10px;
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.dots-panel{
    width:30%;
    margin-bottom: 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.dots-panel .dot{
    width:8px;
    height: 8px;
    border-radius: 4px;
}
.dots-panel .dot-active{
    background: #6D62E5;
}
.dots-panel .dot-unactive{
    background: #BFC0C8;
    
}