.account {
  height: 100vh;
  width: 100%;
  padding: 101px 27px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  transition: all 0.25s;

  &__header {
    font-weight: 600;
    font-size: 26px;
    line-height: 130%;
    color: var(--color--black);
  }
}
