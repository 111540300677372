.personality {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  scroll-behavior: smooth;
  background: #f9f9f9;
  .personality__text {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 121.2%;
    text-align: center;
    color: #222427;
  }

  .personality__header {
    height: 74px;
    z-index: 1;
    width: 100%;
    max-width: 500px;
    position: fixed;
    background: #fef7ed;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    .header__logo {
      display: flex;
      flex-direction: column;
      align-items: center;
      .logo__text {
        margin: 0;
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 12px;
        display: flex;
        margin-top: 9px;
        align-items: center;
        letter-spacing: 0.248359px;
        color: #4380ff;
      }
      .logo__text2 {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 17px;
        letter-spacing: 0.248359px;
        color: #0c1f3d;
        margin: 0;
      }
    }
    .header__button {
      font-family: Poppins;
      font-style: normal;
      font-weight: bold;
      text-decoration: none;
      font-size: 17px;
      text-decoration: none;
      line-height: 108.1%;
      height: 48px;
      width: 172px;
      display: flex;
      outline: none;
      text-decoration: none;
      border: none;
      align-items: center;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      letter-spacing: 0.05em;
      background: #4380ff;
      border-radius: 38px;
      text-transform: capitalize;
      color: #ffffff;
    }
  }
  .personality__forecast {
    width: 100%;
    background: #fef7ed;
    padding: 94px 27px 60px 27px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .forecast__header {
      width: 80%;
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 26px;
      line-height: 130%;
      color: #222427;
    }
    .forecast__block {
      width: 100%;
      position: relative;
      .forecast__img {
        box-shadow: 0px 4px 23px rgba(12, 31, 61, 0.05);
        margin-bottom: 15px;
        border-radius: 14px;
        width: 100%;
      }
      .block__absolute {
        top: 0;
        width: 100%;
        padding: 20px;
        position: absolute;
        .absolute__text1 {
          font-family: Poppins;
          font-style: normal;
          font-weight: normal;
          margin: 0;
          font-size: 14px;
          line-height: 130%;
          color: #222427;
        }
        .absolute__text2 {
          font-family: Poppins;
          font-style: normal;
          margin: 0;
          margin-top: 5px;
          font-weight: 600;
          font-size: 20px;
          line-height: 130%;
          color: #4380ff;
        }
        .absolute__text3 {
          font-family: Poppins;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 127%;
          text-align: center;
          color: #222427;
          margin: 0;
        }
        .absolute__text4 {
          font-family: Poppins;
          font-style: normal;
          font-weight: 600;
          font-size: 50px;
          line-height: 130%;
          text-align: center;
          color: #15c963;
        }
      }
    }
    .forecast__footer {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .footer__block {
        width: 48%;
        background: #ffffff;
        border: 1px solid rgba(231, 233, 237, 0.3);
        box-shadow: 0px 4px 23px rgba(12, 31, 61, 0.05);
        border-radius: 14px;
        padding: 13px 20px 13px 20px;
        p {
          margin: 0;
        }
        .block__text1 {
          font-family: Poppins;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 130%;
          text-align: left;
          color: #222427;
          margin: 0;
          margin-bottom: 8px;
        }
        .block__text2 {
          font-family: Poppins;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          color: #ff6332;
        }
        .block__text3 {
          font-family: Poppins;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 130%;
          color: #222427;
        }
        .block__text4 {
          font-family: Poppins;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 130%;
          color: #4380ff;
        }
      }
    }
    .graph__text {
      color: red;
      font-family: Poppins;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      font-style: normal;
      position: absolute;
      z-index: 1;
      font-weight: 600;
      font-size: 18px;
      line-height: 130%;
      text-align: center;
      color: #222427;
    }
    .forecast__block {
      position: relative;
      margin-top: 20px;
    }
  }
  .personality__day {
    width: 100%;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 0px 0px 0px;
    .day__header {
      font-family: Poppins;
      width: 70%;
      font-style: normal;
      font-weight: 600;
      font-size: 26px;
      line-height: 121.2%;
      color: #222427;
    }
    .day__text1 {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      width: 79%;
      font-size: 15px;
      line-height: 22px;
      letter-spacing: 0.273195px;
      color: #222427;
      opacity: 0.95;
    }
    .day__picks {
      width: 80%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 0px;
      row-gap: 15px;
      .pick {
        display: flex;
        flex-direction: row;
        align-items: center;
        .pick__img {
          width: 20px;
          margin-right: 8px;
        }
        .pick__text {
          margin: 0;
          font-family: Poppins;
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 22px;
          letter-spacing: 0.273195px;
          color: #222427;
        }
      }
    }
    .day__text2 {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 22px;
      letter-spacing: 0.273195px;
      color: #222427;
      opacity: 0.7;
      width: 80%;
      margin-top: 5px;
    }
    .animation__block {
      width: 100%;
      height: 500px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      overflow: hidden;
      .animation__item {
        width: auto;
        height: 46px;
        padding: 10px;
        left: -300px;
        position: absolute;
        margin-bottom: 5px;
        border-radius: 20px;
        background: grey;
        width: auto;
        box-sizing: border-box;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        letter-spacing: 0.273195px;
        color: #ffffff;
        margin: 0;
        margin-top: -70px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .good {
        background: #13c962;
      }
      .bad {
        background: #ff6e41;
      }
    }
  }
  .personality__get {
    background: #fef7ed;
    border: 1px solid rgba(231, 233, 237, 0.4);
    border-radius: 14px;
    width: 90%;
    .get__pick {
      display: flex;
      width: 90%;
      flex-direction: row;
      align-items: center;
      margin-bottom: 16px;
      .pick__img {
        width: 20px;
        margin: 0px 18px 0px 30px;
      }
      .pick__text {
        margin: 0;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #222427;
      }
    }
  }
  .personality__world {
    position: relative;
    width: 100%;
    .world__text-block {
      position: absolute;
      bottom: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .text-block__block {
        padding: 15px 14px 22px 13px;
        width: 90%;
        background: #ffffff;
        border: 1px solid rgba(231, 233, 237, 0.5);
        box-shadow: 0px 4px 23px rgba(12, 31, 61, 0.06);
        border-radius: 14px;
        margin-top: 12px;
        display: flex;
        justify-content: center;
        .block__main-text {
          font-family: Poppins;
          font-style: normal;
          font-weight: 500;
          display: block;
          font-size: 15px;
          text-align: center;
          line-height: 22px;
          text-align: center;
          letter-spacing: 0.273195px;
          width: 90%;
          color: rgba(12, 31, 61, 0.87);
          .block__blue-text {
            font-family: Poppins;
            font-style: normal;
            font-weight: bold;
            font-size: 19.8px;
            line-height: 22px;
            text-align: center;
            letter-spacing: 0.273195px;
            color: #4380ff;
          }
        }
      }
    }
  }
  .personality__version {
    width: 90%;
    background: #fef7ed;
    border: 1px solid rgba(231, 233, 237, 0.4);
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .version__text1 {
      margin-top: -15px;
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 121.2%;
      text-align: center;
      color: #222427;
    }
    .version__text2 {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 22px;
      text-align: center;
      letter-spacing: 0.25px;
      color: #222427;
      width: 90%;
      margin: 0;
      opacity: 0.8;
    }

    .version__button {
      background: #4380ff;
      box-shadow: 0px 3px 7px rgba(56, 146, 247, 0.35);
      border-radius: 38px;
      outline: none;
      border: none;
      text-decoration: none;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80%;
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.248359px;
      color: #ffffff;
      margin: 35px 30px 30px 30px;
    }
  }
  .personality__text2 {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 128%;
    text-align: center;
    color: rgba(34, 36, 39, 0.7);
    width: 80%;
    margin-bottom: 70px;
  }
  .successed {
    width: 100%;
    padding: 40px 0px 50px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f6faf8;
    .successed__text1 {
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 26px;
      line-height: 121.2%;
      text-align: center;
      color: #222427;
    }
    .successed__text2 {
      margin-top: 20px;
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
      text-align: center;
      letter-spacing: 0.273195px;
      color: black;
      width: 80%;
    }
  }
  .personality__prices {
    display: flex;
    flex-direction: row;
    width: 60%;
    margin-left: 20%;
    .prices__new {
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 29px;
      text-align: center;
      color: #4380ff;
      margin-top: 35px;
    }
    .prices__old {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 27px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #71758f;
    }
  }
  .successed__button {
    background: #4380ff;
    box-shadow: 0px 3px 7px rgba(56, 146, 247, 0.35);
    border-radius: 38px;
    outline: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    text-decoration: none;
    width: 80%;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.248359px;
    color: #ffffff;
    margin: 35px 30px 30px 30px;
  }
}
.hello {
  mask-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#000),
    to(transparent)
  );
}
.habits {
  font-family: Poppins;
  font-style: normal;
  width: 90%;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  text-align: center;
  color: linear-gradient(180deg, #191919 45.38%, #151527 100%);
}

.accordion {
  width: 90%;
  .card {
    margin-bottom: 15px;
    box-shadow: none;
    background: #f5faf7;
    border: 1px solid rgba(0, 0, 0, 0.125) !important;

    .card-header {
      background: #f5faf7;
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 130%;
      color: #191919;
      width: 80%;
      border-bottom: none;
      padding: 24px;
    }
    .card-body {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 130%;
      color: #222427;
      line-height: 22px;
      letter-spacing: 0.273195px;
      width: 85%;
      background: #f5faf7;
      padding: 16px;
      opacity: 0.9;
    }
  }
}

.moneyback {
  padding: 24px 13px 24px 22px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  text-align: left;
  font-size: 14px;
  line-height: 130%;
  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  position: relative;
  width: 85%;
  background: white;
  color: #71758f;
}
.sale {
  width: 100%;
  background: linear-gradient(112.86deg, #c8d7ff 36.46%, #e8d0f0 99.98%);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  .sale-block__undertext {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    margin: 0;
    line-height: 22px;
    text-align: center;
    color: #b1b4c0;
  }

  .sale__button {
    background: #6d62e6;
    border-radius: 38px;
    display: flex;
    margin-top: 80px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Poppins;
    width: 90%;
    font-style: normal;
    text-decoration: none;
    font-weight: bold;
    font-size: 17px;
    line-height: 108.1%;
    display: flex;
    height: 48px;
    align-items: center;
    outline: none;
    border: none;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: capitalize;
    color: #ffffff;
  }
  .sale__guarantee {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.248359px;
    color: #0c1f3d;
    width: 37%;
    margin-top: 40px;
    opacity: 0.8;
  }
  .sale__footer {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    margin-bottom: 16px;
    margin-top: 20px;
    font-size: 12px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.248359px;
    color: #0c1f3d;
    opacity: 0.6;
    width: 90%;
  }
}
.personality__modal {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  .personality__modal__charge {
    border-bottom: 1px dashed silver;
    width: 100%;
    padding: 17px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 19px;
    align-items: flex-end;
    .modal-checkout {
      font-family: Poppins;
      font-style: normal;
      font-weight: bold;
      font-size: 25px;
      line-height: 120%;
      text-align: center;
      color: #191919;
    }
    .modal-price {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 27px;
      text-align: center;
      letter-spacing: 0.248359px;
      color: #191919;
    }
  }
  .personality__modal__buttons {
    width: 100%;
    display: flex;
    background: white;
    flex-direction: column;
    align-items: center;
    .secured-payment {
      margin: 0;
      margin-top: 5px;
      font-family: Helvetica Neue;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 27px;
      text-align: center;
      letter-spacing: 0.248359px;
      color: #0c1f3d;
      opacity: 0.54;
    }
    .modal__button__text {
      width: 100%;
      text-align: center;
      margin: 0px;
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 25px;
      text-align: center;
      letter-spacing: 0.25px;
      color: #191919;
    }
    .modal__button {
      width: 90%;
      background: #ffde6f;
      border-radius: 30px;
      height: 46px;

      .modal__button__img-pp {
        height: 20px;
      }
      .modal__button__img-visa {
        height: 40px;
      }
    }
  }
}
.modal-line {
  display: flex;
  padding: 0px 20px 0px 20px;
  flex-direction: row;
  width: 90%;
  align-items: flex-start;
  .modal-line__text {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.248359px;
    color: #0c1f3d;
  }
}
.modal__button {
  width: 100%;
  height: 50px;
  background: #4380ff;
  border-radius: 25px;
  border: none;
  outline: none;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #ffffff;
}
.modal__button:hover {
  outline: none;
}
.sale__button {
  background: #6d62e6;
  border-radius: 38px;
  display: flex;
  margin-top: 80px;
  justify-content: center;
  align-items: center;
  font-family: Poppins;
  width: 90%;
  font-style: normal;
  text-decoration: none;
  font-weight: bold;
  font-size: 17px;
  line-height: 108.1%;
  display: flex;
  height: 48px;
  align-items: center;
  outline: none;
  border: none;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  color: #ffffff;
}
.hey__text {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 29px;
  line-height: 120.2%;
  text-align: center;
  color: #2e323f;
}
.hey__text2 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.248359px;
  color: #0c1f3d;
  width: 80%;
  text-align: center;
  opacity: 0.7;
}
.discount-page__block {
  position: relative;
  width: 85%;
  .block__absolute-block {
    position: absolute;
    width: 100%;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    .block__text {
      font-family: Poppins;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 121.2%;
      text-align: center;
      color: #191919;
      margin-top: -10px;
    }
    .block__text2 {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 22px;
      text-align: center;
      letter-spacing: 0.25px;
      color: #0c1f3d;
      opacity: 0.8;
      width: 80%;
      margin: 0;
    }
  }
}
.one-time-payment {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height, or 122% */

  color: #222427;

  opacity: 0.5;
}
.salescreen__plan {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: linear-gradient(112.86deg, #c8d7ff 36.46%, #e8d0f0 99.98%);
  .plan__header {
    font-family: Poppins;
    width: 80%;
    margin-top: 62px;
    margin-bottom: 16px;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    text-align: center;
    color: #2e323f;
  }
  .prices {
    width: 60%;
    .price__stroked {
      width: 100%;
      text-align: right;
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 19px;
      line-height: 21px;
      letter-spacing: 0.248359px;
      color: #71758f;
    }
    .price__main {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 48px;
      line-height: 22px;
      text-align: center;
      color: #6d62e6;
    }
  }
  .plan__blured {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    color: #71758f;
  }
  .plan__line {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 18px;
    width: 70%;
    .line__text {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: 0.248359px;
      color: #0c1f3d;
      margin: 0;
      margin-left: 16px;
    }
  }
}
.price2 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 120%;
  text-align: center;
  color: #4380ff;
  margin: 0;
}
.price3 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.248359px;
  color: #222427;
  opacity: 0.7;
}
.upsale-modal__text1 {
  width: 90%;
  text-align: left;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 126%;
  color: #222427;
}
.upsale-modal__text2 {
  width: 80%;
  text-align: left;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.273195px;
  color: #222427;
}
.upsale-modal__text3 {
  width: 100%;
  font-family: Poppins;
  font-style: normal;
  margin-top: 25px;
  margin-bottom: 15px;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.273195px;
  color: #222427;
}
.upsale-modal__text4 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  width: 100%;
  font-size: 12px;
  line-height: 27px;
  margin-top: 10px;
  margin-bottom: 0;
  text-align: center;
  letter-spacing: 0.248359px;
  color: #222427;
  opacity: 0.4;
}
.upsale__button {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 108.1%;
  width: 100%;
  background: #4380ff;
  box-shadow: 0px 4px 10px rgba(109, 98, 229, 0.21);
  border-radius: 38px;
  display: flex;
  border: none;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  color: #ffffff;
}
.bonus__header {
  font-family: Poppins;
  font-style: normal;
  margin-top: 20px;
  width: 85%;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #3a7aff;
}
.bonus-line {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.guarantee__text {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  color: #222427;
  opacity: 0.43;
}
@keyframes slide {
  0% {
    left: 0;
  }
  100% {
    left: 200px;
  }
}
@keyframes move {
  0% {
    left: -265px;
  }
  100% {
    left: 100%;
  }
}
@keyframes jiggle {
  0% {
    transform: translateY(-8px);
  }
  50% {
    transform: translateY(8px);
  }
  100% {
    transform: translateY(-8px);
  }
}
.animation__item[data-option="2"],
.animation__item[data-option="3"] {
  -webkit-animation: move 25s linear 2s infinite,
    jiggle 2.1s cubic-bezier(0.37, 0, 0.63, 1) infinite;
  animation: move 25s linear 2s infinite,
    jiggle 2.1s cubic-bezier(0.37, 0, 0.63, 1) infinite;
}
.animation__item[data-option="4"] {
  -webkit-animation: move 25s linear 2s infinite,
    jiggle 1.8s cubic-bezier(0.37, 0, 0.63, 1) infinite;
  animation: move 25s linear 2s infinite,
    jiggle 1.8s cubic-bezier(0.37, 0, 0.63, 1) infinite;
}
.animation__item[data-option="0"] {
  -webkit-animation: move 25s linear 2s infinite,
    jiggle 1.9s cubic-bezier(0.37, 0, 0.63, 1) infinite;
  animation: move 25s linear 2s infinite,
    jiggle 1.9s cubic-bezier(0.37, 0, 0.63, 1) infinite;
}
.animation__item[data-option="1"] {
  -webkit-animation: move 25s linear 2s infinite,
    jiggle 2s cubic-bezier(0.37, 0, 0.63, 1) infinite;
  animation: move 25s linear 2s infinite,
    jiggle 2s cubic-bezier(0.37, 0, 0.63, 1) infinite;
}
.animation__item[data-option="5"] {
  -webkit-animation: move 25s linear 2s infinite,
    jiggle 2s cubic-bezier(0.37, 0, 0.63, 1) infinite;
  animation: move 25s linear 2s infinite,
    jiggle 2s cubic-bezier(0.37, 0, 0.63, 1) infinite;
}
.hided__text {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  color: #222427;
  opacity: 0.25;
}
.get__button {
  background: #4380ff;
  box-shadow: 0px 3px 7px rgba(56, 146, 247, 0.35);
  border-radius: 38px;
  outline: none;
  border: none;
  height: 50px;
  text-decoration: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.248359px;
  color: #ffffff !important;
  margin: 35px 30px 30px 30px;
}
.get__button:active {
  background: #4380ff;
  box-shadow: 0px 3px 7px rgba(56, 146, 247, 0.35);
  border-radius: 38px;
  outline: none;
  border: none;
  height: 50px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.248359px;
  color: #ffffff;
  margin: 35px 30px 30px 30px;
}
.hello__text1 {
  font-family: Poppins;
  font-style: normal;
  margin-top: 20px;
  font-weight: 600;
  font-size: 20px;
  line-height: 121.2%;
  text-align: center;
  color: #222427;
}
.hello__text2 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  width: 90%;
  margin-bottom: 50px;
  letter-spacing: 0.273195px;
  color: #222427;
  opacity: 0.7;
}
