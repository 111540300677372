.authorized-info {
	display: flex;
	flex-direction: column;
	align-items: center;

	&__description {
		padding: 0 31px;
		margin: 20px 0 30px;
		font-size: 18px;
		line-height: 27px;
		text-align: center;

		opacity: 0.9;
	}

	&__link {
		width: 100%;
		max-width: 335px;
		height: 50px;
	}
}
