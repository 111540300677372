.home{
    .home__header{
        background: #FEF7ED;
        padding-top: 12px;
        width: 100%;
        padding-bottom: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .header__img{
            width: 32px;
            height: 32px;
        }
        .header__text{
            margin:0;
            font-family: Poppins;
            font-style: normal;
            font-weight: bold;
            margin-left: 6px;
            font-size: 14.688px;
            line-height: 22px;
            color: #222427;
        }
    }
    .home__text3{
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        width: 90%;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0.273195px;
        color: #222427;
    }
    .home__text4{
        font-family: Poppins;
        font-style: normal;
        width: 90%;
        font-weight: 600;
        font-size: 26px;
        line-height: 120%;        
        text-align: center;
        color: #222427;       
    }
    .home__button1{
        width: 90%;
        margin-top: 30px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 108.1%;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: 0.05em;
        text-transform: capitalize;
        color: #FFFFFF;
        background: #4380FF;
        box-shadow: 0px 4px 10px rgba(109, 98, 229, 0.21);
        border-radius: 38px;
        outline: none;
        border: none;
        margin-bottom: 12px;
    }
}
.home__button1{
    width: 90%;
    margin-top: 30px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    max-width: 500px;
    font-size: 16px;
    line-height: 108.1%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.05em;
    text-transform: capitalize;
    color: #FFFFFF;
    background: #4380FF;
    box-shadow: 0px 4px 10px rgba(109, 98, 229, 0.21);
    border-radius: 38px;
    outline: none;
    position: fixed;
    border: none;
    bottom: 12px;
}
.home__text1{
    width: 90%;
    text-align: center;
    margin:0;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    text-align: left;
    font-size: 28px;
    line-height: 120%;
    color: #222427;
    margin-top: 80px;
}
.home__text2{
    width: 90%;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    margin-top: 20px;
    line-height: 22px;
    letter-spacing: 0.273195px;
    color: #222427;
}