.success {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  p {
    width: 100%;
    text-align: center;
  }
  .success__header {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 130%;
    text-align: center;
    margin-top: 100px;
    color: #191919;
  }
  .success__underheader {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    width: 70%;
    color: #7e808e;
  }
  .success__img {
    width: 90%;
    max-width: 500px;
  }
  .success__body {
    margin-top: -50px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.25px;
    color: rgba(12, 31, 61, 0.6);
    margin-bottom: 0;
  }
  .success__underbody {
    font-family: Poppins;
    margin-top: 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.25px;
    color: #0c1f3d;
  }
  .success__button {
    max-width: 500px;
    width: 90%;
    display: flex;
    justify-content: center;
    max-width: 500px;
    align-items: center;
    height: 48px;
    background: #4380ff;
    font-family: Poppins;
    font-style: normal;
    text-decoration: none;
    font-weight: 600;
    font-size: 17px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.248359px;
    color: #ffffff;
    border: none;
    outline: none;
    border-radius: 20px;
    img {
      height: 25px;
      margin-right: 5px;
      margin-top: -1px;
    }
  }
}
.habitbox-app__container {
  border-top: 1px solid silver;
  margin-top: 26px;
  width: 100%;
  padding: 27px 27px 0px 24px;
  .container__text1 {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.25px;
    color: rgba(34, 36, 39, 0.6);
  }
  .container__buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .buttons__button {
      width: 49%;
      align-self: flex-start;
    }
  }
}
