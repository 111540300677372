.first-question__text{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    margin-top:-10px;
    width: 90%;
    font-size: 15px;
    line-height: 22px;
    color: #222427;
    opacity: 0.9;
}
.block__container--row{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top:22px;
    justify-content: flex-start;
    width: 90%;
    .container__item{
        background: #F9F7F4;
        cursor: pointer;
        display: flex;
        width: 100%;
        border-bottom: 1px solid #C7DAFF;
        flex-direction: row;
        align-items: center;
        margin-right: 12px;
        padding:12px 10px;
        box-sizing: border-box;
        .container-item__img{
            height:26px;
            margin-left: 20px;
        }
        .container-item__checkbox-container{
            border-radius: 8px;
            height: 26px;
            width: 26px;
            border:1px solid #C7DAFF;
            overflow: hidden;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            transition-duration: .2s;
        }
        .checkbox-container--active{
            background: #4380FF;
            border:1px solid #4380FF;

        }
        .checkbox-container--unactive{
            background: white;
        }
        
        .container-item__text{
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 22px;
            color: #222427;
            opacity: 0.9;
            flex: none;
            order: 1;
            flex-grow: 0;
            margin: 0px 0px 0px 5px;
        }
    }
}