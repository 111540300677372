.plan__block{
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    .block__img{
        width: 95%;
        margin-top: 85px;
    }
    .block__text1{
        margin-top: 50px;
        width: 80%;
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 120%;
        text-align: center;
        color: #2E323F;
    }
    .block__text2{
        width: 80%;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        color: #70748E;

    }
}