.burger-menu {
  max-width: 480px;
  margin: 0 auto;
  padding: 35px 30px 30px 27px;

  &-container {
    position: fixed;
    bottom: 0;
    left: 100%;

    overflow-y: auto;
    overflow-x: hidden;

    z-index: 10000;

    width: 100%;

    height: calc(100% - 50px);

    transition: all 0.5s;

    background: #fff;

    &--open {
      left: 0%;

      .burger-menu__option {
        animation-name: slidein;

        &:nth-of-type(1) {
          animation-duration: 0.6s;
        }

        &:nth-of-type(2) {
          animation-duration: 0.7s;
        }

        &:nth-of-type(3) {
          animation-duration: 0.8s;
        }
      }

      .burger-menu-contacts {
        animation-name: slidein;
        animation-duration: 0.9s;
      }
    }
  }

  &-options {
    margin-bottom: 40px;

    .burger-menu__option {
      margin-bottom: 30px;

      display: flex;
      justify-content: space-between;
      align-items: center;

      font-weight: 600;
      font-size: 26px;
      line-height: 130%;

      transition: color 0.35s;

      a {
        color: #222427;
        text-decoration: none;

        &:active {
          color: #2e71ff;
        }
      }

      svg {
        transition: transform 0.35s;
        stroke: #a3a3a2;
      }

      &--open {
        color: #2e71ff;

        svg {
          stroke: #2e71ff;
          transform: rotate(-180deg);
        }
      }
    }
  }

  .terms-and-policies {
    padding: 0 3px 0 20px;

    height: 0;
    overflow: hidden;

    transition: height 0.5s;

    &--open {
      height: 251px;
    }

    &__option {
      margin-top: 17px;
      padding-bottom: 14px;

      border-bottom: 1px solid #f2f3f5;

      font-weight: 600;
      font-size: 17px;
      line-height: 25px;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 40px;
      }

      a {
        color: #222427;
        text-decoration: none;

        &:active {
          color: #2e71ff;
        }
      }
    }
  }

  &-contacts {
    padding: 30px 21px 32px;

    display: grid;
    grid-template-columns: 24px auto;
    gap: 6px;

    background: #f5faf7;
    border-radius: 15px;

    &__email-icon {
      width: 100%;
    }

    .contacts-info {
      padding-top: 2px;
      &__label {
        margin-bottom: 7px;

        font-size: 16px;
        line-height: 130%;
        color: #808897;
      }

      &__link {
        font-weight: 600;
        font-size: 18px;
        line-height: 120%;
        color: #2e71ff;
        text-decoration: none;
      }
    }
  }
}
