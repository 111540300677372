*{
  margin:0;
  padding:0;
  scroll-behavior: smooth;
}
.none-image{
  opacity:0;
  height:1px;
  width:1px;
  position:absolute;
  z-index:-99999;
  bottom: 0;
  display: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}