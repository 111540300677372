.confirm{
    display: flex;
    flex-direction: column;
    align-items: center;  
    position: fixed;
    bottom: 0px;
    width: 100%;
    max-width: 500px;
    .confirm__button{
        width: 90%;
        border-radius: 38px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 108.1%;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.05em;
        text-decoration: none;
        text-transform: capitalize;
        height: 48px;
        color: #FFFFFF;
        margin-bottom:0px;
        border: none;
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .confirm__button--active{
        background: #4380FF;
    }
    .confirm__button--unactive{
        background: #C7DAFF;
        pointer-events: none;
    }
    .confirm__text{
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #70748E;
        margin-bottom: 8px;
    } 
}

