.amazing__block{
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    .block__img{
        width: 60%;
        margin-top: 85px;
    }
    .block__text1{
        margin-top: 50px;
        width: 80%;
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 120%;
        text-align: center;
        color: #222427;
    }
    .block__text2{
        width: 80%;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 22px;
        text-align: center;
        color: #222427;
        opacity: 0.9;
    }
}