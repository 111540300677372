.sale-footer {
  margin: 20px auto;
  font-family: Poppins;
  width: 100%;
  max-width: 475px;

  display: flex;
  flex-direction: column;
  align-items: center;

  &-money-back {
    margin-bottom: 30px;

    display: flex;
    flex-direction: column;
    align-items: center;

    text-align: center;

    &-top {
      margin: 0 30px 30px;
    }

    &__image {
      margin-left: 10px;

      width: 33px;
      height: 33px;
    }

    &__title {
      font-weight: 600;
      font-size: 25px;
      line-height: 125%;

      text-align: center;
    }

    &__description {
      margin: 0 23px 20px;

      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 128%;

      text-align: center;

      color: rgba(34, 36, 39, 0.7);

      span {
        font-weight: 500;
        color: #4380ff;
      }
    }
  }

  .money-back {
    position: relative;

    padding: 30px 19px;

    width: 100%;

    text-align: center;

    border: 2px solid #4380ff;
    box-sizing: border-box;
    border-radius: 16px;

    display: flex;
    flex-direction: column;
    align-items: center;

    &-container {
      margin: 43px 0 50px;
      padding: 0 20px;

      width: 100%;
    }

    &-wrapper {
      padding: 10px;

      background: #ffffff;
      box-shadow: 0px 4px 23px rgba(12, 31, 61, 0.1);
      border-radius: 14px;
    }

    &__title {
      margin-bottom: 20px;

      font-weight: 600;
      font-size: 25px;
      line-height: 125%;
    }

    &__description {
      font-size: 12px;
      line-height: 18px;
      text-align: center;

      color: rgba(34, 36, 39, 0.7);

      a {
        color: rgba(34, 36, 39, 0.7);
      }
    }

    &__ribbon {
      position: absolute;
      bottom: -65px;
      right: 12px;

      height: 90.2899398803711px;
      width: 65.25947570800781px;
    }
  }

  &__logos {
    width: 100%;
    max-width: 321px;
  }

  &-links {
    max-width: 375px;

    padding: 14.6px 34px 17px;

    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .link {
    font-weight: 500;
    font-size: 12px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.248359px;

    color: #222427;

    opacity: 0.9;
  }

  &__adress {
    margin: 20px 0 19px;

    width: calc(100% - 75px);
    max-width: 266px;

    font-size: 12px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.248359px;

    color: #383a3d;

    opacity: 0.6;
  }
}
