$modal-height: 100%;

.personality__modal__charge2{
    margin: 0;
    margin-bottom: 17px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 17px 0px 17px;
    .modal-checkout{
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 120%;
        text-align: center;
        color: #191919;
        opacity: 0.55;
    }
    .modal-price{
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 120%;
        text-align: center;
        color: #191919;
        opacity: 0.55;
    }
}

.modal-background{
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 3;
    opacity: .7;
    background: black;
}

.new-modal{
    width: 100%;
    background: white;
    border-radius: 8px 8px 0px 0px;
    padding: 20px;
    z-index: 4;
    position: absolute;
    height: $modal_height;
    position: fixed;
    overflow: scroll;
    bottom: -$modal_height;
    max-width: 500px;
    overflow: hidden;
    max-height: 90%;
    .new-modal__header{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        color: silver;
    }
    .new-modal__body{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        margin-top: 10px;
        .body__header{
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            width: 80%;
            font-size: 23px;
            text-align: center;
            color: #191919;
        }
    }
    .new-modal__body-selector{
        display: flex;
        width: 100%;
        position: relative;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        border-radius: 5px;
        height: 60px;
        background: #FFFFFF;
        border: 2px solid #286EFF;
        box-sizing: border-box;
        box-shadow: 0px 4px 14px rgba(34, 36, 39, 0.12);
        border-radius: 12px;
        margin-bottom: 8px;
        .body-selector__text{
            margin: 0;
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            padding: 20px;
            line-height: 20px;
            display: flex;
            align-items: center;
            text-transform: capitalize;
            color: #286EFF;
        }
    }
    .selector__checkbox{
        position: absolute;
        right: 20px;
        width: 20px;
        height: 20px;
        top:0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
    }
    .new-modal__body-selector--unactive{
        border: 2px solid #E1E1E1;
        background: #F7F8FB;
        .body-selector__text{
            color: #808897;
        }
    }
    }
    
    .new-modal__table{
        width: 100%;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .table__row{
            width: 100%;
            margin-bottom: 7px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .row__text1{
                font-family: Poppins;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                margin: 0;
                color: #808897;
            }
            .row__text2{
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                margin: 0;
                line-height: 24px;
                text-align: right;
                color: #FD5E2C;
            }
            .row__text3{
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                margin: 0;
                margin-top: 20px;
                font-size: 24px;
                line-height: 24px;
                color: #2E71FF;
            }

        }
    }
.new-modal--active{
    animation-name: fadeinn;
    animation-duration: 0.3s;
    animation-timing-function:ease-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}
.new-modal--unactive{
    animation-name: fadeoutt;
    animation-duration: 0.3s;
    animation-timing-function:ease-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes fadeinn{
    0%{
        bottom: -$modal_height;
    }
    100%{
        bottom: 0px;
    }
}
@keyframes fadeoutt{
    0%{
        bottom: 0px;
    }
    100%{
        bottom: -$modal_height;
    }
}
.d-none{
    display: none;
}
#solid-payment-form-iframe{
    width:100%;
    height:300px;
    margin-top: 20px;
    border:none;
}
.paypal-container{
    width: 100%;
    position: relative;
}
#solid-payment-form-container{
    margin-top: 0px;
    min-height: 350px;
    width: 90%;
}
#solid-payment-form-iframe{
    height: 350px;
}
.own-modal{
    width: 95%;
    max-width: 450px;
    background: white;
    border-radius: 10px;
    // overflow-y: scroll;
    max-height: 90%;
    pointer-events: auto;

}
.backdrop-new-modal{
    width: 100%;
    height: 100%;
    position: fixed;
    pointer-events: none;
    background: rgba(0, 0, 0, .6);
    display: flex;
    align-items: center;
    justify-content: center;
}
::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: none;
}