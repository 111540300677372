.text-answers__block{
    width: 100%;
    background: #F9F7F4;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    .block__container{
        width: 90%;
        margin-top: 25px;
        margin-bottom: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .container__item{
            min-height: 64px;
            padding:16px;            
            width: 100%;
            background: white;
            border-radius: 20px;
            display: flex;
            flex-direction: row;
            position: relative;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 12px;
            .container-item__img{
                width: 60px;
                height: 60px;
                margin-left: 5px;
            }
            .container-item__text{
                font-family: Poppins;
                font-style: normal;
                font-weight: normal;
                font-size: 15px;
                line-height: 22px;
                color: #222427;
                opacity: 0.9;
                margin: 0;
            }
            .container-item__text--active{
                color: #222427;
            }
            .container-item__text--unactive{
                color: #222427;
            }
        }
        .container__item--active{
            border: 2px solid #4380FF;
            background: #F1F6FF;
        }
        .container__item--unactive{
            border: 2px solid white;
        }
    }
}
.text-answers__block--fadeout{
    animation-name: fadeout;
    animation-duration: .5s;
}
.text-answers__block--fadein{
    animation-name: fadein;
    animation-duration: .5s;
}