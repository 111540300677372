.input {
  padding: 15px 29px 16px;
  margin: 30px 0 15px;

  width: 100%;
  max-height: 50px;

  font-family: "Poppins", sans-serif;
  font-size: 15px;
  line-height: 22px;

  background: var(--color--white);
  border: 1px solid rgba(27, 24, 48, 0.13);
  border-radius: 13px;

  &:focus {
    outline: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #f1f6ff inset !important;
    box-shadow: 0 0 0 30px #f1f6ff inset !important;
  }

  &--valid {
    border: 1.5px solid #2e71ff;
    background: #f1f6ff;
    box-shadow: 0px 0px 8px rgba(116, 89, 172, 0.08);
  }

  &::placeholder {
    opacity: 0.15;
  }
}
