#root{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  min-height: 100vh;
}
.App{
  max-width: 500px;
  width: 100%;
  min-height: 100vh;
  background: #F9F7F4;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@keyframes fadein{
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}
@keyframes fadeout{
  0%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}
button:focus{
  outline: none;

}