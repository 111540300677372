.delivery__text{
    margin-top:20px;
    width:90%;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    color: #222427;
}
.delivery__input{
    background: #FFFFFF;
    border: 1px solid #D0DFFF;
    box-sizing: border-box;
    border-radius: 13px;
    height: 64px;
    width: 90%;
    outline: none;
    font-family: Poppins;
    padding:18px 20px 18px 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    color: #222427;
}
.delivery__input::placeholder{
    opacity: .4;
}
.delivery__input--active{
    border: 1.5px solid #4380FF;
    background: #F1F6FF;
}
.delivery__input--unactive{

}