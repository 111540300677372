.eight-answers__block{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #F9F7F4;
    min-height: 100vh;
    .block__container{
        width: 375px;
        margin-top: 25px;
        margin-bottom: 60px;
        display: flex;
        flex-wrap: wrap;
        min-height: 350px;
        position: relative;

        .container__item{
            position: absolute;
            background: #FFFFFF;
            box-sizing: border-box;
            border-radius: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: auto;
            padding: 4px 10px 4px 10px;           
            .container__text{
                width:90%;
                text-align: center;
                margin: 0;
                font-family: Poppins;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 120%;
                text-align: center;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .container__text--active{
                color: #286EFF;
            }
            .container__text--unactive{
                color: #3F414E;
            }
        }
        .bubble1{
            width:121px;
            left:27px;
            top:0;
            height:121px;
            animation-name: bubble1-flow;
            animation-duration: .5s;
        }
        .bubble2{
            width:79px;
            height:79px;
            left:169px;
            top:0;
            animation-name: bubble2-flow;
            animation-duration: .5s;
        }
        .bubble3{
            width:85px;
            left:248px;
            top:60px;
            height:85px;
            animation-name: bubble3-flow;
            animation-duration: .5s;
        }
        .bubble4{
            width: 98px;
            left:134px;
            top:85px;
            height:98px;
            animation-name: bubble4-flow;
            animation-duration: .5s;
        }
        .bubble5{
            width:106px;
            height:106px;
            left:23px;
            top:140px;
            animation-name: bubble5-flow;
            animation-duration: .5s;

        }
        .bubble6{
            width:100px;
            height:100px;
            left:125px;
            top:200px;
            animation-name: bubble6-flow;
            animation-duration: .5s;
        }
        .bubble7{
            width:121px;
            left:231px;
            height:121px;
            top:160px;
            animation-name: bubble7-flow;
            animation-duration: .5s;
        }
        .bubble10{
            width:89px;
            left: 140px;
            height:89px;
            top:310px;
            animation-name: bubble10-flow;
            animation-duration: .5s;
        }
        .bubble9{
            width:79px;
            right: 55px;
            height:79px;
            top:290px;
            animation-name: bubble9-flow;
            animation-duration: .5s;
        }
        .bubble8{
            width:79px;
            left: 40px;
            height:79px;
            top:265px;
            animation-name: bubble8-flow;
            animation-duration: .5s;
        }
        .container__item--active{
            border: 1.5px solid #4380FF;
            color: #286EFF;
            background: #F1F6FF;
        }
        .container__item--unactive{
            border: 1.5px solid #FFFFFF;
        }
    }
}
.eight-answers__block--fadein{
    animation-name: fadein;
    animation-duration: .5s;
}
.eight-answers__block--fadeout{
    animation-name: fadeout;
    animation-duration: .5s;
}
@keyframes bubble1-flow{
    0%{
        left:-100px;
        top:-30px;
    }
    100%{
        left:27px;
        top:0;
    }
}
@keyframes bubble2-flow{
    0%{
        left:169px;
        top:-50px;
    }
    100%{
        left:169px;
        top:0;
    }
}
@keyframes bubble3-flow{
    0%{
        left:350px;
        top:50px;
    }
    100%{
        left:248px;
        top:60px;
    }
}
@keyframes bubble4-flow{
    0%{
        left:-20px;
        top:80px;
    }
    100%{
        left:134px;
        top:85px;
    }
}
@keyframes bubble5-flow{
    0%{
        left:-60px;
        top:150px;
    }
    100%{
        left:23px;
        top:140px;
    }
}
@keyframes bubble6-flow{
    0%{
        left:-50px;
        top:350px;
    }
    100%{
        left:125px;
        top:200px;
    }
}
@keyframes bubble7-flow{
    0%{
        left:320px;
        top:180px;
    }
    100%{
        left:231px;
        top:160px;
    }
}
@keyframes bubble10-flow{
    0%{
        left:-50px;
        top:320px;
    }
    100%{
        left: 140px;
        top:310px;
    }
}
@keyframes bubble9-flow{
    0%{
        left:-50px;
        top:320px;
    }
    100%{
        right: 55px;
        top:290px;
    }
}
@keyframes bubble8-flow{
    0%{
        left:-50px;
        top:320px;
    }
    100%{
        left: 40px;
        top:265px;
    }
}