.warning-message {
  margin-bottom: 20px;

  max-width: 335px;

  display: grid;
  grid-template-columns: 20px 1fr;
  gap: 10px;

  background: #fbe9ea;
  border: 1px solid rgba(12, 31, 61, 0.01);
  box-sizing: border-box;
  border-radius: 14px;

  padding: 12px 20px 17px;

  &__image {
    width: 20px;
    height: 20px;
  }

  &__description {
    margin: 0;
    font-size: 15px;
    line-height: 22px;
  }
}
